<template>
  <div id="course-create-details-tab" v-if="data" @keyup.ctrl.enter="lab_update_data">
    <div class="vx-col w-full">
      <div class="vx-row mb-4">
        <div class="vx-col sm:w-1/3 w-full mb-2">
          <vs-input v-model="dataLocal.id" class="w-full" label-placeholder="Pool ID" readonly disabled />
        </div>
        <div class="vx-col sm:w-1/3 w-full mb-2">
          <vs-input v-model="dataLocal.vendor" class="w-full" label-placeholder="Pool Vendor" />
        </div>
        <div class="vx-col sm:w-1/3 w-full mb-2">
          <vs-input v-model="dataLocal.product" class="w-full" label-placeholder="Pool Product" />
        </div>
      </div>

      <!---<div class="vx-row mb-6">
        <div class="vx-col w-full flex gap-2 items-center">
          <p class="text-md">Verify Account</p>
          <vs-switch v-model="dataLocal.login" class="self-center mr-2"/>
        </div>
      </div>--->

      <div class="vx-row">
        <div class="vx-col w-full flex gap-4">
          <vs-button class="mb-2" color="success" type="filled" @click="lab_update_data">Update Pool </vs-button>
          <vs-button class="mb-2" color="warning" type="border" @click="reset_data">Reset </vs-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import vSelect from 'vue-select';
import axios from 'axios';
import VueJsonPretty from 'vue-json-pretty';
import 'vue-json-pretty/lib/styles.css';
export default {
  data() {
    return {
      dataLocal: JSON.parse(JSON.stringify(this.data)),
      lab_description_counter: null,

      pools: [],

      status_options: ['active', 'hidden', 'disabled'],
    };
  },
  components: {
    vSelect,
    VueJsonPretty,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  methods: {
    lab_update_data() {
      this.$emit('update_lab_data', this.dataLocal);
    },
    reset_data() {
      this.dataLocal = JSON.parse(JSON.stringify(this.data));
    },
    get_pools() {
      this.$httpLabs
        .get('labs/pools')
        .then((response) => {
          this.pools = response.data.rows.map((obj) => obj.id);
        })
        .catch((error) => {});
    },
  },
  created() {
    this.get_pools();
  },
};
</script>

<style scoped></style>
