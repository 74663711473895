<!-- =========================================================================================
  File Name: EditCourse.vue
  Description: User Edit Page

========================================================================================== -->

<template>
  <div id="page-user-edit">
    <vs-alert color="danger" title="Lab Not Found" :active.sync="errorOccurred">
      <span>Lab pool record with id: {{ $route.params.labId }} not found. </span>
      <span>
        <span>Check </span><router-link :to="{ name: 'admin-list-lab-pools' }" class="text-inherit underline">All Labs Pools</router-link>
      </span>
    </vs-alert>

    <vx-card class="mb-4" v-if="lab_data">
      <div slot="no-body" class="tabs-container px-6 pt-6">
        <vs-tabs v-model="activeTab" class="tab-action-btn-fill-conatiner">
          <vs-tab label="Details" icon-pack="feather" icon="icon-edit">
            <labs-details-tab class="mt-4" :data="lab_data" v-on:update_lab_data="update_lab_data" :key="refresh_key" />
          </vs-tab>
        </vs-tabs>
      </div>
    </vx-card>
  </div>
</template>

<script>
import LabsDetailsTab from './PoolDetailsTab.vue';
import axios from 'axios';

export default {
  components: {
    LabsDetailsTab,
  },
  data() {
    return {
      lab_data: null,
      errorOccurred: false,

      refresh_key: 0,

      activeTab: 0,
    };
  },
  watch: {
    activeTab() {
      this.load_lab_data(this.$route.params.labId);
    },
  },
  methods: {
    load_lab_data() {
      this.$httpLabs
        .get(`labs/pools/${this.$route.params.labId}`)
        .then((response) => {
          if (response.status === 200) {
            this.lab_data = response.data;
            this.refresh_key++;
          }
        })
        .catch((error) => {
          this.errorOccurred = true;
          this.error_msg = error;
        });
    },
    update_lab_data(data) {
      this.$vs.loading();

      this.$httpLabs
        .patch(`labs/pools/${this.$route.params.labId}`, data)
        .then((response) => {
          if (response.status === 200) {
            this.$vs.loading.close();
            this.$vs.notify({
              title: 'Updated Lab Pool Successfully',
              text: `${data.id} has been updated.`,
              color: 'success',
              position: 'top-right',
            });
            this.load_lab_data(this.$route.params.labId);
          }
        })
        .catch((exception) => {
          this.$vs.loading.close();

          let error = 'An unknown error occurred while updating this pool';
          if (exception.response) {
            error = exception.response.data.message;
          }

          return this.$vs.notify({
            title: 'Failed to update pool',
            text: error,
            color: 'danger',
            position: 'top-right',
          });
        });
    },
  },
  created() {
    this.load_lab_data(this.$route.params.labId);
  },
};
</script>
